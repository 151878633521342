<script>
import { defineComponent, reactive, computed, ref } from "vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useErrors } from "@/compositions/useErrors";
import Swal from "@/plugins/swal";
import * as api from "@/plugins/api";

export default defineComponent({
  name: "TeamInvite",
  setup() {
    const route = useRoute();
    const store = useStore();
    const errors = useErrors();

    const state = reactive({
      teamId: route.params.id,
      positions: [],
      employee: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        position_id: "",
        invite_type: "invite"
      }
    });
    const invite_email = ref('');

    errors.errorInitContainers(["create_invite"]);

    async function getPositions() {
      const { data } = await api.fetch({
        url: `/positions`,
        resource: "v1"
      });
      state.positions = data;
    }

    getPositions();

    function sendInvite() {
      errors.errorSet([]);
      store
        .dispatch("team/employeeSendInvite", {
          teamId: state.teamId,
          body: {
            email: invite_email.value
          }
        })
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "Invite sent successfully!",
            icon: "success",
            confirmButtonText: "Cool"
          });
          invite_email.value = '';
        })
        .catch(e => {
          errors.errorSet(e.errors);
        });
    }

    async function sendInviteCreate() {
      errors.errorSet([], "create_invite");
      try {
        await store.dispatch("team/employeeSendInviteCreate", {
          teamId: state.teamId,
          body: state.employee
        });

        Swal.fire({
          title: "Success!",
          text: "Invite sent successfully!",
          icon: "success",
          confirmButtonText: "Cool"
        });
      } catch (e) {
        errors.errorSet(e.errors, "create_invite");
      }
    }

    return {
      positions: computed(() => state.positions),
      invite_email,
      employee: state.employee,
      sendInvite,
      sendInviteCreate,
      ...errors
    };
  }
});
</script>

<template>
  <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">
      Invite an member to your team
    </h2>
  </div>
  <div class="pos intro-y grid grid-cols-12 gap-5">
    <!-- BEGIN: Post Content -->
    <div class="intro-y col-span-12 lg:col-span-8">
      <div class="pos intro-y grid grid-cols-12 gap-5">
        <!-- BEGIN: Post Content -->
        <div class="intro-y col-span-12 lg:col-span-8">
          <div class="post intro-y overflow-hidden box mt-5">
            <div
              class="post__tabs nav-tabs flex flex-col sm:flex-row bg-gray-200 dark:bg-dark-2 text-gray-600"
            >
              <a
                class="tooltip w-full sm:w-40 py-4 text-center cursor-pointer flex justify-center items-center"
                :class="{ active: employee.invite_type === 'invite' }"
                @click.prevent="employee.invite_type = 'invite'"
              >
                <i class="ri-mail-send-fill mr-2" /> Invite
              </a>
              <a
                class="w-full py-4 text-center cursor-pointer flex justify-center items-center"
                :class="{ active: employee.invite_type === 'create_invite' }"
                @click.prevent="employee.invite_type = 'create_invite'"
              >
                <i class="ri-user-shared-2-fill mr-2" />Create & Invite
              </a>
            </div>
          </div>
          <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
            <div
              v-if="employee.invite_type === 'invite'"
              class="mt-5"
            >
              <div
                class="mt-2"
                :class="{ 'has-error': errorHas('email') }"
              >
                <input
                  v-model="invite_email"
                  type="text"
                  class="input input--lg border w-full pr-10 placeholder-theme-13"
                  placeholder="Email"
                >
                <span
                  v-if="errorHas('email')"
                  class="pristine-error text-theme-6 mt-2"
                >{{ errorGet("email") }}.
                </span>
                <span
                  v-if="errorHas('user')"
                  class="pristine-error text-theme-6 mt-2"
                >{{ errorGet("user") }}.
                </span>
              </div>
              <button
                class="mt-4 button text-white bg-theme-1 shadow-md flex items-center"
                @click.prevent="sendInvite"
              >
                Invite
              </button>
            </div>
            <div
              v-else
              class="mt-5"
            >
              <div
                :class="{
                  'has-error': errorHas('first_name', 'create_invite')
                }"
              >
                <input
                  v-model="employee.first_name"
                  type="text"
                  class="input input--lg border w-full pr-10 placeholder-theme-13"
                  placeholder="First name"
                >
                <span
                  v-if="errorHas('first_name', 'create_invite')"
                  class="pristine-error text-theme-6 mt-2"
                >{{ errorGet("first_name", "create_invite") }}.
                </span>
              </div>
              <div
                class="mt-2"
                :class="{ 'has-error': errorHas('last_name', 'create_invite') }"
              >
                <input
                  v-model="employee.last_name"
                  type="text"
                  class="input input--lg border w-full pr-10 placeholder-theme-13"
                  placeholder="Last name"
                >
                <span
                  v-if="errorHas('last_name', 'create_invite')"
                  class="pristine-error text-theme-6 mt-2"
                >{{ errorGet("last_name", "create_invite") }}.
                </span>
              </div>
              <div
                class="mt-2"
                :class="{ 'has-error': errorHas('email', 'create_invite') }"
              >
                <input
                  v-model="employee.email"
                  type="text"
                  class="input input--lg border w-full pr-10 placeholder-theme-13"
                  placeholder="Email"
                >
                <span
                  v-if="errorHas('email', 'create_invite')"
                  class="pristine-error text-theme-6 mt-2"
                >{{ errorGet("email", "create_invite") }}.
                </span>
              </div>
              <div
                class="mt-2"
                :class="{ 'has-error': errorHas('password', 'create_invite') }"
              >
                <input
                  v-model="employee.password"
                  type="text"
                  class="input input--lg border w-full pr-10 placeholder-theme-13"
                  placeholder="Password"
                >
                <span
                  v-if="errorHas('password', 'create_invite')"
                  class="pristine-error text-theme-6 mt-2"
                >{{ errorGet("password", "create_invite") }}.
                </span>
              </div>
              <div class="mt-2">
                <input
                  v-model="employee.password_confirmation"
                  type="text"
                  class="input input--lg border w-full pr-10 placeholder-theme-13"
                  placeholder="Password confirmation"
                >
              </div>
              <div
                class="mt-2"
                :class="{
                  'has-error': errorHas('position_id', 'create_invite')
                }"
              >
                <select
                  v-model="employee.position_id"
                  class="input login__input input--lg border-gray-300 block mt-4"
                >
                  <option value="">
                    Chose position
                  </option>
                  <option
                    v-for="(position, index) in positions"
                    :key="index"
                    :value="position.id"
                  >
                    {{ position.name }}
                  </option>
                </select>
                <span
                  v-if="errorHas('position_id', 'create_invite')"
                  class="pristine-error text-theme-6 mt-2"
                >
                  {{ errorGet("position_id", "create_invite") }}
                </span>
              </div>
              <button
                class="mt-4 button text-white bg-theme-1 shadow-md flex items-center"
                @click.prevent="sendInviteCreate"
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Post Content -->
  </div>
</template>
